/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react'
import { MainLayout } from '~layouts'

import '~styles/app.css'

// eslint-disable-next-line react/display-name
export const wrapPageElement = ({ element: page }) => {
  // All routing logic is in the Page component

  return page
}

export const wrapRootElement = ({ element: root }) => {
  return (
    <MainLayout>
      {root}
    </MainLayout>
  )
}
